
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface HirePurchase {
  carPrice: string;
  downPayment: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  name: "hire-purchase",
  components: {
    ErrorMessage,
    Field,
    Form,
    ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const monthlyPrincipal = 0;
    const monthlyInterest = 0;
    const monthlyRepayment = 0;
    const totalInterest = 0;
    const yearlyPrincipal = 0;
    const yearlyInterest = 0;
    const yearlyRepayment = 0;
    const submitButton1 = ref<HTMLElement | null>(null);
    const hirePurchaseCalculated = false;

    const monthlyPaymentSchedule = {
      month: "",
      interest: 0,
      repayment: 0,
      balance: 0,
    };

    const yearlyPaymentSchedule = {
      year: "",
      interest: 0,
      repayment: 0,
      balance: 0,
    };

    const paymentScheduleYearly = [];
    const paymentScheduleMonthly = [];

    return {
      id: route.params.id ?? null,
      store: useStore(),
      monthlyPrincipal,
      monthlyInterest,
      monthlyRepayment,
      totalInterest,
      yearlyPrincipal,
      yearlyInterest,
      yearlyRepayment,
      submitButton1,
      hirePurchase: {
        carPrice: "90000",
        downPayment: "10000",
        loanPeriod: 10,
        interestRate: 4,
      },
      monthlyPaymentSchedule,
      yearlyPaymentSchedule,
      paymentScheduleYearly,
      paymentScheduleMonthly,
      hirePurchaseCalculated,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const hirePurchaseValidator = Yup.object({
      // name: Yup.string().required().label("Name"),
      raw: Yup.object({
        carPrice: Yup.string().required().label("Car Price"),
        loanPeriod: Yup.number().required().label("Loan Period"),
        interestRate: Yup.number().required().label("Interest Rate"),
      }),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Hire Purchase", ["Financial Calculators"]);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const activeName = ref("stepUp1");

    const handleClick = (tab: string, event: Event) => {
      console.log(tab, event);
    };

    return {
      hirePurchaseValidator,
      translate,
      nFormat,
      activeName,
      handleClick,
    };
  },
  methods: {
    init() {
      if (this.id) {
        this.store.dispatch(Actions.GET_CALCULATOR, this.id).then(() => {
          this.hirePurchase = this.store.getters.getCalculatorData.raw;
          this.hirePurchase.carPrice = this.hirePurchase.carPrice.replace(
            ",",
            ""
          );
          this.hirePurchase.downPayment = this.hirePurchase.downPayment.replace(
            ",",
            ""
          );
          this.calculate();
        });
      }
    },
    calculate() {
      this.paymentScheduleYearly = [];
      this.paymentScheduleMonthly = [];

      if (!this.hirePurchase.downPayment) {
        this.hirePurchase.downPayment = "0";
      }

      const loanAmount =
        parseFloat(this.hirePurchase.carPrice) -
        parseFloat(this.hirePurchase.downPayment);
      const loanPeriod = this.hirePurchase.loanPeriod;
      const monthlyLoanPeriod = loanPeriod * 12;
      const interestRate = this.hirePurchase.interestRate;

      const totalInterest = (interestRate / 100) * loanAmount * loanPeriod;
      const totalLoanPlusInterest = loanAmount + totalInterest;
      const monthlyInterest = totalInterest / monthlyLoanPeriod;
      const monthlyInstallment = totalLoanPlusInterest / monthlyLoanPeriod;
      const monthlyPrincipal = monthlyInstallment - monthlyInterest;

      this.monthlyRepayment = parseFloat(monthlyInstallment.toFixed(2));
      this.totalInterest = parseFloat(totalInterest.toFixed(2));
      this.monthlyInterest = parseFloat(monthlyInterest.toFixed(2));
      this.monthlyPrincipal = parseFloat(monthlyPrincipal.toFixed(2));

      let totalMonthlyInterest = 0;
      let totalMonthlyRepayment = 0;
      let totalMonthlyBalance = 0;

      console.log(totalInterest);
      console.log(totalLoanPlusInterest);
      console.log(monthlyLoanPeriod);

      let yearCount = 0;
      let monthCount = 0;
      for (let m = 0; m < monthlyLoanPeriod; m++) {
        totalMonthlyInterest += monthlyInterest;
        totalMonthlyRepayment += monthlyInstallment;
        totalMonthlyBalance = totalLoanPlusInterest - totalMonthlyRepayment;

        this.monthlyPaymentSchedule = {
          month: this.ordinal_suffix_of(m + 1),
          interest: parseFloat(totalMonthlyInterest.toFixed(2)),
          repayment: parseFloat(totalMonthlyRepayment.toFixed(2)),
          balance: parseFloat(totalMonthlyBalance.toFixed(2)),
        };

        this.paymentScheduleMonthly.push(this.monthlyPaymentSchedule);

        monthCount += 1;
        if (monthCount == 12) {
          yearCount += 1;

          this.yearlyPaymentSchedule = {
            year: this.ordinal_suffix_of(yearCount),
            interest: parseFloat(totalMonthlyInterest.toFixed(2)),
            repayment: parseFloat(totalMonthlyRepayment.toFixed(2)),
            balance: Math.abs(parseFloat(totalMonthlyBalance.toFixed(2))),
          };

          this.paymentScheduleYearly.push(this.yearlyPaymentSchedule);

          monthCount = 0;
        }
      }

      console.log(this.paymentScheduleMonthly);

      this.hirePurchaseCalculated = true;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },
    onSubmit(values) {
      let action;
      // if (this.id) {
      //   values.id = this.id;
      //   action = Actions.UPDATE_CALCULATOR;
      // } else {
      //   action = Actions.STORE_CALCULATOR;
      // }
      action = Actions.STORE_CALCULATOR;

      this.store.dispatch(action, values).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Calculator has been saved!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
